<template>
  <!-- table -->
  <div class="statistics site-card-tab">
    <el-tabs v-model="activeName" @tab-click="orderTab">
      <el-tab-pane :label="$t('所有投注')" name="first">
        <div class="statistics__body game-list-cell" v-if="gameIndexOrderListEmpty == 1">
          <div class="statistics__inner" style="min-height: 560px;">
            <table class="clickable table-all" style="--animation-duration:500ms;">
              <thead>
                <tr>
                  <th>{{ $t('游戏图标') }}</th>
                  <th>{{ $t('游戏名称') }}</th>
                  <th class="hidden-xs-only">{{ $t('时间') }}</th>
                  <th>{{ $t('游戏者') }}</th>
                  <!-- <th>{{$t('下注金额')}}</th> -->
                  <th>{{ $t('盈利金额') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class v-for="(item, index) in gameIndexOrderList" :key="`gameIndexOrderList${index}`">
                  <td class="table-flex">
                    <div class="tab-img-cell">
                      <img class="tab-img" :src="item.game_icon" alt="">
                    </div>
                  </td>
                  <td>
                    <span class="td-name currency__font" tab="all">
                      {{ $t(item.game_name) }}
                    </span>
                  </td>
                  <td class="hidden-xs-only">
                    <span class="td-time">
                      <span class="datetime currency__font">{{ item.gb_bettime }}</span>
                    </span>
                  </td>
                  <td>
                    <div class="user user--clickable user-wrap" tab="all">
                      <img :src="item.avatar" />
                      <div class="user__wrap">
                        <span class="user__name currency__font">{{ item.username }}</span>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <img
                          width="20"
                          height="20"
                          :src="require('@/assets/images/'+$store.state.currencyIcon)"
                          loading="lazy"
                          class="currency__icon"
                        />
                        <div class="currency__balances-wrap">
                          <div class="currency__balances">
                            <span
                              class="currency__balances--balance"
                              style="font-size: 12px;"
                            >
                              <span
                                style="color: #fff"
                                class="font-digits"
                              >{{ numFormat(item.gb_price) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td> -->
                  <td>
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <div class="currency__balances-wrap">
                          <div class="currency__balances currency__balances__flex">
                            <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                            <span class="currency__balances--balance" :class="item.status" style="font-size: 18px;margin: 2px 0 0 8px;">
                              <span>{{ numFormat(item.gb_winprice) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="no-data" v-if="gameIndexOrderListEmpty == 2">
          <span>{{ $t('暂无数据') }}</span>
        </div>
      </el-tab-pane>
      <template v-if="$store.state.isLoginStatu">
        <el-tab-pane :label="$t('我的投注')" name="second">
          <template v-if="userGameOrderListEmpty == 1">
            <div class="statistics__body my-game-list-cell">
              <div class="statistics__inner" style="min-height: 560px;">
                <table class="clickable table-all" style="--animation-duration:500ms;">
                  <thead>
                    <tr>
                      <th>{{ $t('游戏名称') }}</th>
                      <th class="hidden-xs-only">{{ $t('时间') }}</th>
                      <th>{{ $t('下注金额') }}</th>
                      <th>{{ $t('盈利金额') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class v-for="(item, index) in userGameOrderList" :key="`userGameOrderList${index}`">
                      <td>
                        <div class="currency__balances__flex">
                          <div class="tab-img-cell" style="margin-right: 12px;">
                            <img class="tab-img" :src="item.game_icon" alt="">
                          </div>
                          <span class="td-name currency__font" tab="all">
                            {{ $t(item.game_name) }}
                          </span>
                        </div>
                      </td>
                      <td class="hidden-xs-only">
                        <span class="td-time">
                          <span class="datetime currency__font">{{ item.gb_bettime }}</span>
                        </span>
                      </td>
                      <td>
                        <div class="td-currency" tab="all">
                          <span class="currency">
                            <div class="currency__balances-wrap">
                              <div class="currency__balances currency__balances__flex">
                                <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                                <span class="currency__balances--balance" style="font-size: 18px;margin-top: 2px;margin: 2px 0 0 8px;">
                                  <span style="color: #fff" class="font-digits">{{ numFormat(item.gb_price) }}</span>
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="td-currency" tab="all">
                          <span class="currency">
                            <div class="currency__balances-wrap">
                              <div class="currency__balances currency__balances__flex">
                                <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                                <span class="currency__balances--balance" :class="item.status" style="font-size: 18px;margin: 2px 0 0 8px;">
                                  <span>{{ numFormat(item.gb_winprice) }}</span>
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-if="userGameOrderListEmpty == 2">
            <div class="no-data">
              <span>{{ $t('暂无数据') }}</span>
            </div>
          </template>
        </el-tab-pane>
      </template>
      <el-tab-pane :label="$t('High rollers')" name="third">
        <div class="statistics__body game-list-cell" v-if="high_bets && high_bets.length > 0">
          <div class="statistics__inner" style="min-height: 560px;">
            <table class="clickable table-all" style="--animation-duration:500ms;">
              <thead>
                <tr>
                  <th>{{ $t('游戏名称') }}</th>
                  <th class="hidden-xs-only">{{ $t('时间') }}</th>
                  <th>{{ $t('游戏者') }}</th>
                  <th class="hidden-xs-only">{{ $t('下注金额') }}</th>
                  <th>{{ $t('盈利金额') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class v-for="(item, index) in high_bets" :key="`high_bets${index}`">
                  <td>
                    <div class="currency__balances__flex">
                      <div class="tab-img-cell" style="margin-right: 12px;">
                        <img class="tab-img" :src="item.game_icon" alt="">
                      </div>
                      <span class="td-name currency__font" tab="all">
                        {{ $t(item.game_name) }}
                      </span>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <span class="td-time">
                      <span class="datetime currency__font">{{ item.gb_betdate }}</span>
                    </span>
                  </td>
                  <td>
                    <div class="user user--clickable user-wrap" tab="all">
                      <img :src="item.avatar" />
                      <div class="user__wrap">
                        <span class="user__name currency__font">{{ item.username }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <div class="currency__balances-wrap">
                          <div class="currency__balances currency__balances__flex">
                            <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                            <span class="currency__balances--balance" style="font-size: 18px;margin: 2px 0 0 8px;">
                              <span style="color: #fff" class="font-digits">{{ numFormat(item.gb_price) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <div class="currency__balances-wrap">
                          <div class="currency__balances currency__balances__flex">
                            <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                            <span class="currency__balances--balance" :class="item.status" style="font-size: 18px;margin: 2px 0 0 8px;">
                              <span>{{ numFormat(item.gb_winprice) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="no-data" v-else>
          <span>{{ $t('暂无数据') }}</span>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('Rare wins')" name="fourth">
        <div class="statistics__body game-list-cell" v-if="high_wins && high_wins.length > 0">
          <div class="statistics__inner" style="min-height: 560px;">
            <table class="clickable table-all" style="--animation-duration:500ms;">
              <thead>
                <tr>
                  <th>{{ $t('游戏名称') }}</th>
                  <th class="hidden-xs-only">{{ $t('时间') }}</th>
                  <th>{{ $t('游戏者') }}</th>
                  <th class="hidden-xs-only">{{ $t('下注金额') }}</th>
                  <th>{{ $t('盈利金额') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class v-for="(item, index) in high_wins" :key="`high_wins${index}`">
                  <td>
                    <div class="currency__balances__flex">
                      <div class="tab-img-cell" style="margin-right: 12px;">
                        <img class="tab-img" :src="item.game_icon" alt="">
                      </div>
                      <span class="td-name currency__font" tab="all">
                        {{ $t(item.game_name) }}
                      </span>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <span class="td-time">
                      <span class="datetime currency__font">{{ item.gb_betdate }}</span>
                    </span>
                  </td>
                  <td>
                    <div class="user user--clickable user-wrap" tab="all">
                      <img :src="item.avatar" />
                      <div class="user__wrap">
                        <span class="user__name currency__font">{{ item.username }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <div class="currency__balances-wrap">
                          <div class="currency__balances currency__balances__flex">
                            <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                            <span class="currency__balances--balance" style="font-size: 18px;margin: 2px 0 0 8px;">
                              <span style="color: #fff" class="font-digits">{{ numFormat(item.gb_price) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="td-currency" tab="all">
                      <span class="currency">
                        <div class="currency__balances-wrap">
                          <div class="currency__balances currency__balances__flex">
                            <div class="golad-light">
                              <svgIcon icon="icon-gold" class="currency__golad"></svgIcon>
                            </div>
                            <span class="currency__balances--balance" :class="item.status" style="font-size: 18px;margin: 2px 0 0 8px;">
                              <span>{{ numFormat(item.gb_winprice) }}</span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="no-data" v-else>
          <span>{{ $t('暂无数据') }}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  all_game_order_api,
  game_high_order_api,
  user_game_order_api,
} from "@/api/cybSports";
import svgIcon from "@/components/svg.vue";

export default {
  components: {svgIcon},
  data() {
    return {
      activeName: "first",
      high_wins: [],
      high_bets: [],
      userGameOrderList: [], //首页游戏用户订单
      userGameOrderListEmpty: 0, //首页游戏用户订单缺省
      gameIndexOrderListEmpty: 0, //首页游戏订单缺省
      gameOrderListPage: 1,
      userGameOrderList: [], //首页游戏用户订单
      userGameOrderListEmpty: 0, //首页游戏用户订单缺省
      // 排行榜
      currentIndex: 0, // 当前滚动的索引
      itemHeight: 0, // 列表项高度
      moveDistance: 0, // translateY距离
      initialStyle: {
        animation: 'enterAnimation 1s ease-in-out', // 初始进入动画
        opacity: '1',
      },
    }
  },
  methods: {
    // 获取首页游戏订单
    getGameIndexOrderList() {
      all_game_order_api(13,this.gameOrderListPage).then(res => {
        if (res && res.data.code == 1) {
          this.gameIndexOrderList = res.data.data;
          if (this.gameIndexOrderList.length > 0) {
            this.gameIndexOrderListEmpty = 1;
          } else {
            this.gameIndexOrderListEmpty = 2;
          }
        }
      });
    },
    getGameHighOrder() {
      game_high_order_api(13).then(res => {
        if (res && res.data.code == 1) {
          this.high_bets = res.data.data.high_bet;
          this.high_wins = res.data.data.high_win;
        }
      });
    },
    // 获取我的投注
    getUserGameOrder() {
      user_game_order_api().then(res => {
        if (res && res.data.code == 1) {
          this.userGameOrderList = res.data.data;
          if (this.userGameOrderList.length > 0) {
            this.userGameOrderListEmpty = 1;
          } else {
            this.userGameOrderListEmpty = 2;
          }
        }
      });
    },
    orderTab() {
      if (this.activeName == 'first') {
        this.getGameIndexOrderList();
      } else if (this.activeName == 'second') {
        this.getUserGameOrder();
      } else if (this.activeName == 'third' || this.activeName == 'fourth') {
        this.getGameHighOrder();
      }
    }
  },
  mounted() {
    this.getGameIndexOrderList();
    this.getGameHighOrder();
    // 登录数据
    if (this.$store.state.isLoginStatu) {
      this.getUserGameOrder();
    }
  },
};
</script>

<style scoped>
.td-name {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

@media screen and (max-width:768px) {
  .td-name {
    max-width: 25vw;
  }

  table.table-all td:nth-child(4),
  table.table-all th:nth-child(4) {
    min-width: 60px;
  }
}

.statistics__inner {
  overflow: auto;
}

.site-card-tab ::v-deep .el-tabs__item.is-active {
  border-radius: 5px;
}

.tab-img-cell {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;

}

.tab-img {
  width: 100%;
  height: 100%;
  transform: scale(3);
  transform-origin: center;
  transition: transform 0.3s;
}

.currency__font {
  font-weight: 100;
  color: #55657e;
}

table tbody td {
  height: 71px;
  padding: 15px 5px 0 8px;
}

table td:last-child, table th:last-child {
    padding-right: 16px;
}

.currency__balances__flex {
  display: flex;
  align-items: center;
}

.currency__balances--balance {
  color: #1bb83d;
}

.user-wrap {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .user__wrap {
    display: none;
  }

  .user-wrap>img {
    margin-top: 3px;
  }
}

.user-wrap>img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.game-list-cell {
  position: relative;
  overflow: hidden;
  height: 568px;
}

.game-list-cell::after {
  background: linear-gradient(181deg, #0d131c00 21.34%, #0d131c 99.18%);
  bottom: 0;
  content: "";
  height: 354px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}


.game-list-cell tbody {
  position: relative;
  transition: transform 1s ease-in-out;
  animation: scrollUp 13s linear infinite;
  animation-delay: 6s;
}

@keyframes scrollUp {
    0%, 7.7% { transform: translateY(0); }            
    15.4%, 23.1% { transform: translateY(-71px); }    
    30.8%, 38.5% { transform: translateY(-142px); }   
    46.2%, 53.8% { transform: translateY(-213px); }   
    61.5%, 69.2% { transform: translateY(-284px); }   
    76.9%, 84.6% { transform: translateY(-355px); }   
    92.3%, 100% { transform: translateY(-426px); }
}

table.table-all td:first-child {
  background-color: transparent;
}

table tbody td:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #0D131C;
}

.my-game-list-cell tbody tr {
  list-style: none;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
  background-color: rgba(22, 31, 44, 0.8);
}

.game-list-cell tbody tr {
  list-style: none;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
  background-color: rgba(22, 31, 44, 0.8);
}

/* 根据每个 tr 的位置添加延迟效果 */

.my-game-list-cell tbody tr:nth-child(1),
.game-list-cell tbody tr:nth-child(1) {
  animation-delay: 0.5s;
}

.my-game-list-cell tbody tr:nth-child(2),
.game-list-cell tbody tr:nth-child(2) {
  animation-delay: 1s;
}

.my-game-list-cell tbody tr:nth-child(3),
.game-list-cell tbody tr:nth-child(3) {
  animation-delay: 1.5s;
}

.my-game-list-cell tbody tr:nth-child(4),
.game-list-cell tbody tr:nth-child(4) {
  animation-delay: 2s;
}

.my-game-list-cell tbody tr:nth-child(5),
.game-list-cell tbody tr:nth-child(5) {
  animation-delay: 2.5s;
}

.my-game-list-cell tbody tr:nth-child(6),
.game-list-cell tbody tr:nth-child(6) {
  animation-delay: 3s;
}

.my-game-list-cell tbody tr:nth-child(7),
.game-list-cell tbody tr:nth-child(7) {
  animation-delay: 3.5s;
}

.my-game-list-cell tbody tr:nth-child(8),
.game-list-cell tbody tr:nth-child(8) {
  animation-delay: 4s;
}

.my-game-list-cell tbody tr:nth-child(9),
.game-list-cell tbody tr:nth-child(9) {
  animation-delay: 4.5s;
}

.my-game-list-cell tbody tr:nth-child(10),
.game-list-cell tbody tr:nth-child(10) {
  animation-delay: 5s;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.currency__golad {
  width: 28px;
  height: 28px;
}

.golad-light {
  position: relative;
}

.golad-light::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -2px;
  width: 32px;
  height: 22px;
  background: radial-gradient(circle at center, rgba(255, 215, 0, 0.6), rgba(255, 215, 0, 0));
  border-radius: 50%;
  animation: topGlowPulse 3s ease-in-out infinite;
  pointer-events: none;
}

@keyframes topGlowPulse {
  0%, 100% {
    opacity: 0.2;
    transform: scale(0.9);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
}
</style>
